import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";

// components
import BlogDropdown from "components/Dropdowns/BlogDropdown";

export default function ListBlog({ color }) {
  const [blogs, setBlogs] = useState([]);

  // Function to fetch blogs
  const refreshBlogs = async () => {
    try {
      const response = await axios.get('https://paytack.com/paytackapi/blogs/');
      const blogsData = response.data;

      // Fetch authors for each blog and attach the author's name to the blog
      const blogsWithAuthors = await fetchAuthors(blogsData);
      setBlogs(blogsWithAuthors);
    } catch (error) {
      console.error("Error fetching blogs: ", error);
    }
  };

  // Function to fetch authors by user ID
  const fetchAuthors = async (blogsData) => {
    const uniqueUserIds = [...new Set(blogsData.map(blog => blog.addedby))];
    const authors = {};

    // Fetch author details for each unique `addedby` (user ID)
    for (const userId of uniqueUserIds) {
      try {
        const userResponse = await axios.get(`https://paytack.com/paytackapi/users/${userId}`);
        console.log(" userResponse",  userResponse);
        authors[userId] = userResponse?.data[0]?.name; // Assuming the API returns user data with a "name" field
      } catch (error) {
        console.error(`Error fetching author with ID ${userId}: `, error);
      }
    }
    console.log("authors", authors);
    // Attach the author name to each blog object
    return blogsData.map(blog => ({
      ...blog,
      authorName: blog.addedby || 'Unknown',
    }));
  };

  useEffect(() => {
    refreshBlogs();
  }, []);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Blogs</h6>
            <Link to="/admin/blogs/create">
              <button
                className="bgPrimaryColor text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                New Blog
              </button>
            </Link>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Title
                </th>
                <th
                  style={{ width: "230px" }}
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Body
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Author
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Date Posted
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {blogs.reverse().map((blog) => (
                <tr key={blog.id}>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        (color === "light" ? "text-blueGray-600" : "text-white")
                      }
                    >
                      {blog.title}
                    </span>
                  </td>
                  <td className="border-t-0 px-6 max-w-200-px align-middle border-l-0 border-r-0 text-xs p-4">
                    <div
                      className="line-clamp"
                      dangerouslySetInnerHTML={{ __html: blog.body }}
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex">{blog.authorName}</div>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <div className="flex">{blog.dateadded}</div>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {blog.status === 0 ? (
                      <i className="fas fa-circle text-orange-500 mr-2">
                        &nbsp;&nbsp;Pending
                      </i>
                    ) : blog.status === 1 ? (
                      <i className="fas fa-circle text-emerald-500 mr-2">
                        &nbsp;&nbsp;Published
                      </i>
                    ) : (
                      <i className="fas fa-circle text-red-500 mr-2">
                        &nbsp;&nbsp;Archived
                      </i>
                    )}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right cursor-pointer">
                    <BlogDropdown blog={blog} refreshBlogs={refreshBlogs} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

ListBlog.defaultProps = {
  color: "light",
};

ListBlog.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
