import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Blogs from "views/admin/Blogs.js";
import Users from "views/admin/Users.js";
import CreateUser from "views/admin/components/Users/CreateUser.js";
import CreateBlog from "views/admin/components/Blogs/CreateBlog.js";
import UpdateBlog from "views/admin/components/Blogs/UpdateBlog";
import UpdateUser from "views/admin/components/Users/UpdateUser";
import BlogView from "views/admin/components/Blogs/BlogView.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/blogs" exact component={Blogs} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/users/create" exact component={CreateUser} />
            <Route path="/admin/blogs/create" exact component={CreateBlog} />
            <Route path="/admin/users/update" component={UpdateUser} />
            <Route path="/admin/blogs/update" component={UpdateBlog} />
            <Route path="/admin/blogs/view" component={BlogView} />
            
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
