import React from "react";

// components
import RecentListBlog from "./components/Blogs/RecentListBlog";
import ListUser from "./components/Users/ListUser";

export default function Dashboard() {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-10/12 mb-12 xl:mb-0 px-4">
          <RecentListBlog />
        </div>
        {/* <div className="w-full xl:w-4/12 px-4">
          <ListUser />
        </div> */}
      </div>
    </>
  );
}
