import React, { useState } from "react";
import { Link } from "react-router-dom";
// import "../../assets/styles/hide.css";

export default function Footer() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

 

  const closeModal = () => {
    document.body.style.overflow = 'auto';
    setModalIsOpen(false);
  };
  return (
    <>
      <footer className="relative bg-blueGray-600">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          {/* <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg> */}
        </div>
        <div className="pt-20 mx-auto px-4">
          <div className="flex flex-wrap text-left lg:text-left">
            <div className="w-full lg:w-4/12 px-4">
              <img
                src={require("assets/img/greenLogo.png")}
                alt="PayTack Logo"
                width="140px"
                className="logo-img"
              ></img>
              <h6 className="text-lg mt-0 mb-4 text-white">
                Good customer loyalty in every move
              </h6>
              <div className="mt-6 lg:mb-0 mb-6">
              <a href="https://facebook.com/paytack" target="_blank" >
                <button
                    className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button" onc
                  >
                    <i className="fab fa-facebook-square"></i>
                </button>
              </a>
              <a href="https://linkedin.com/company/paytack" target="_blank" >
                <button
                    className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-linkedin"></i>
                </button>
              </a>
              <a href="https://instagram.com/paytack_loyalty" target="_blank" >
                <button
                    className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-instagram"></i>
                </button>
              </a>
                
                
              </div>
              <h6 className="text-lg mt-0 mb-2 text-white">
                info@paytack.com
              </h6>
            </div>
            <div className="w-full lg:w-8/12 px-4">
              <div className="flex flex-wrap items-top mb-9">
              
                <div className="w-full lg:w-3/12 px-4 ml-auto">
                  <span className="block uppercase text-white text-base font-semibold mb-2">
                    Quick Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        <Link to="/">Home</Link>
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        <Link to="/business">For Business</Link>
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        <Link to="/">How it works?</Link>
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        <Link to="/blogs">Insights</Link>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="w-full lg:w-3/12 px-4">
                  <span className="block uppercase text-white text-base font-semibold mb-2">
                    Information
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        Become a Partner
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        <Link to="/termsconditions">Terms & Conditions</Link>
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        href=""
                      >
                        <Link to="/privacypolicy">Privacy Policy & GDPR</Link>
                      </a>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="w-full lg:w-3/12 px-4 ml-auto">
                  <span className="block uppercase text-white text-base font-semibold mb-2">
                    CONTACT US
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        
                      >
                        Denmark,
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        
                      >
                        Højbro Pl. 10, 1200 København,
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        
                      >
                        CVR: 40209751.
                      </a>
                    </li>
                    
                  </ul>
                </div> */}
                <div className="w-full lg:w-3/12 px-4 ml-auto">
                <span className="block uppercase text-white text-base font-semibold mb-2">
                    CONTACT US
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        
                      >
                        Sweden,
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        
                      >
                        111 36 Stockholm,
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-blueGray-800 font-normal block pb-2 text-base"
                        
                      >
                        Drottninggatan 86.
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-base text-blueGray-200 font-semibold py-1">
                All rights reserved. Copyright © {new Date().getFullYear()} paytack.com{" "}.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
