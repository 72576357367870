
import React from "react";
import { useHistory, Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import axios from "axios";
import Swal from 'sweetalert2';

const UserDropdown = (props) => {

  const navigate = useHistory();
  let timerInterval;
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const viewUser = (user) => {
    closeDropdownPopover();
    console.log(user);
    navigate.push('/admin/users/view', {
      state: {
        id: user.id,
        name: user.name,
        username: user.username,
        email: user.email,
        password: user.password,
        role: user.role,
        status: user.status
      }
    })
  }

  const editUser = (user) => {
    closeDropdownPopover();
    console.log(user);
    navigate.push('/admin/users/update', {
      state: {
        id: user.id,
        name: user.name,
        username: user.username,
        email: user.email,
        password: user.password,
        role: user.role,
        status: user.status
      }
    })
  }

  function suspendUser(user){
    closeDropdownPopover();
    let headers= { 
        'Accept':'*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Headers' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }

    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('password', user.password);
    formData.append('role', user.role);
    formData.append('username', user.username);
    formData.append('status', 2);

    axios.put('https://paytack.com/paytackapi/users/', formData, {headers}).then(function(response){
        console.log(response.data);
        if(response.data['status'] === 1){
              Swal.fire({
                icon:  "success",
                title: "Success!",
                html: "User suspended. Redirecting to Users.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log("I was closed by the timer");
                  navigate.push('/admin/users/', {
                  });
                  props.refreshUsers();
                }
              });
            }
            else{

            }
            
    });
  
  }

  function activateUser(user){
    closeDropdownPopover();
    let headers= { 
        'Accept':'*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Headers' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }

    const formData = new FormData();
    formData.append('id', user.id);
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('password', user.password);
    formData.append('role', user.role);
    formData.append('username', user.username);
    formData.append('status', 1);

    axios.put('https://paytack.com/paytackapi/users/', formData, {headers}).then(function(response){
        console.log(response.data);
        if(response.data['status'] == 1){
              Swal.fire({
                icon:  "success",
                title: "Success!",
                html: "User activated. User can access the dashboard as per their role. Redirecting to Users.",
                timer: 5000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log("I was closed by the timer");
                  navigate.push('/admin/users/', {
                  });
                  props.refreshUsers();
                }
              });
            }
    });
  
  }

  return (
    <>
      <a
        className="text-blueGray-500 py-1 px-3"
        
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <ul>
            <li
              
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              }
              onClick={() => {
                activateUser(props.user)
              }}
            >
                <i style={{color: "green"}} className={"fas fa-check mr-2 text-sm "}></i>
                Activate
            </li>
            <li
              
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              }
              onClick={() => {
                viewUser(props.user)
              }}
            >
                <i style={{color: "teal"}} className={"fas fa-eye mr-2 text-sm "}></i>
                View
            </li>
            <li
              
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              }
              onClick={() => {
                editUser(props.user)
              }}
            >
                <i style={{color: "orange  "}} className={"fas fa-edit mr-2 text-sm "}></i>
                Edit                                             
            </li>
            <li
              
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
              }
              onClick={() => {
                suspendUser(props.user)
              }}
            >
                <i style={{color: "red"}} className={"fas fa-archive mr-2 text-sm "}></i>
                Suspend
            </li>
        </ul>
      </div>
    </>
  );
};

export default UserDropdown;
