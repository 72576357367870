import React from "react";

// components

import UsersTable from "views/admin/components/Users/ListUser";


export default function Tables() {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <UsersTable/>
        </div>
      </div>
    </>
  );
}